import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import matter from "gray-matter";

const AdminDashboard = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Authentication Check
  useEffect(() => {
    const checkAuth = async () => {
      try {
        // const response = await fetch("http://localhost:5001/api/check-auth", {
            const response = await fetch("https://api.blog.footietix.co.uk/api/check-auth", {
          method: "GET",
          credentials: "include", // Include cookies for authentication
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error("Auth Check Failed:", errorData.error); // Debugging logs
          throw new Error("Unauthorized");
        }
      } catch (err) {
        console.error("Unauthorized Access - Redirecting to Login:", err);
        navigate("/admin-login"); // Redirect to login page if unauthorized
      }
    };

    checkAuth();
  }, [navigate]);

  // Fetch Posts
  useEffect(() => {
    const importPosts = async () => {
      try {
        const markdownFiles = require.context("../posts", false, /\.md$/);
        const posts = await Promise.all(
          markdownFiles.keys().map(async (fileName) => {
            const file = await import(`../posts/${fileName.replace("./", "")}`);
            const content = await fetch(file.default).then((res) => res.text());
            const { data } = matter(content);
            return {
              id: fileName.replace("./", "").replace(".md", ""),
              title: data.title,
              date: data.date,
            };
          })
        );
        setPosts(posts);
      } catch (err) {
        console.error("Failed to load posts:", err); // Debugging logs
        setError("Failed to load posts.");
      }
    };

    importPosts();
  }, []);

  // Logout Function
  const handleLogout = async () => {
    try {
    //   const response = await fetch("http://localhost:5001/api/admin-logout", {
        const response = await fetch("https://api.blog.footietix.co.uk/api/admin-logout", {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Logout Failed:", errorData.error); // Debugging logs
        throw new Error("Logout Failed");
      }

      navigate("/admin-login"); // Redirect to login page after logout
    } catch (err) {
      console.error("Logout Error:", err); // Log any logout errors
      setError("Failed to logout. Please try again.");
    }
  };

  // Error Display
  if (error) {
    return (
      <div className="container mx-auto p-4">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
        >
          Logout
        </button>
      </div>

      {/* Create New Post Button */}
      <Link
        to="/admin/new"
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mb-4 inline-block"
      >
        Create New Post
      </Link>

      {/* Post List */}
      <div className="space-y-4">
        {posts.map((post) => (
          <div
            key={post.id}
            className="border border-gray-300 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <h2 className="text-xl font-bold">{post.title}</h2>
            <p className="text-gray-500">{post.date}</p>
            <Link
              to={`/admin/edit/${post.id}`}
              className="bg-green-500 text-white py-2 px-4 mt-2 rounded hover:bg-green-600 inline-block"
            >
              Edit
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;