import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Post from "./components/Post";
import AdminDashboard from "./admin/AdminDashboard";
import BlogEditor from "./admin/BlogEditor";
import AdminLogin from "./admin/AdminLogin";
import Layout from "./Layout";

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Ensure checkAuth finishes before rendering

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // const response = await fetch("http://localhost:5001/api/check-auth", {
          const response = await fetch("https://api.blog.footietix.co.uk/api/check-auth", {

          method: "GET",
          credentials: "include", // Send cookies
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (err) {
        console.error("Auth check failed:", err);
        setIsAuthenticated(false);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    checkAuth(); // Only runs for protected routes
  }, []);

  if (loading) return <div>Loading...</div>; // Prevent rendering until auth check completes

  return isAuthenticated ? children : <Navigate to="/admin-login" />;
};

// App Component
function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes (With Layout) */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/post/:id" element={<Post />} />
        </Route>

        {/* Admin Login Route (Without Layout) */}
        <Route path="/admin-login" element={<AdminLogin />} />

        {/* Protected Routes (With Layout) */}
        <Route path="/admin" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="new"
            element={
              <ProtectedRoute>
                <BlogEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute>
                <BlogEditor />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;