import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import matter from "gray-matter";

// Dynamically import all Markdown files
const importPosts = async () => {
  const markdownFiles = require.context("../posts", false, /\.md$/);
  const posts = await Promise.all(
    markdownFiles.keys().map(async (fileName) => {
      const file = await import(`../posts/${fileName.replace("./", "")}`);
      const content = await fetch(file.default).then((res) => res.text());
      const { data, content: body } = matter(content); // Parse frontmatter and content

      // Clean excerpt by splitting content into words (ignoring metadata)
      const excerpt = body
        .split(" ") // Split into words
        .slice(0, 40) // Take first 20 words
        .join(" ") + "..."; // Add ellipsis

      return {
        id: fileName.replace("./", "").replace(".md", ""), // Extract ID
        title: data.title, // From frontmatter
        date: data.date, // From frontmatter
        excerpt: excerpt, // Cleaned excerpt
      };
    })
  );
  return posts;
};

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    importPosts().then(setPosts); // Load posts dynamically
  }, []);

  return (
    <main className="pt-10">
        <div className="mx-auto px-0 py-6 lg:px-4 max-w-5xl">
            <h1 className="mt-0 break-words text-5xl font-display font-bold leading-tight">FootieTix Blog</h1>
            <p className="whitespace-pre-wrap my-4">This blog is the official source for updates to the FootieTix platform and home to our thoughts on the world of football. You can also follow the @FootieTix account on <a href="https://twitter.com/FootieTix" className="text-primary hover:underline">Twitter</a> or <a href="https://instagram.com/FootieTix" className="text-primary hover:underline">Instagram</a> for all the latest news.</p>
        </div>
        <div className="mx-auto px-0 py-6 lg:px-4 max-w-5xl">
        {posts.map((post) => (
            <div
            key={post.id}
            className="border border-gray-300 rounded-lg p-4 mb-4"
            >
            <h2 className="font-semibold flex-1 text-2xl lg:text-3xl leading-snug mb-4">
              <a href={`/post/${post.id}`} className="hover:underline">{post.title}</a>
            </h2>
            <p className="text-gray-500 text-sm mb-4">{post.date}</p>
            <p className="mb-4">{post.excerpt}</p>
            <Link to={`/post/${post.id}`} className="text-primary hover:underline">
                Read More
            </Link>
            </div>
        ))}
        </div>
    </main>
    
  );
};

export default Home;