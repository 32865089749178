import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  // Handle Login
  const handleLogin = async () => {
    setLoading(true); // Start loading spinner
    setError(""); // Clear any previous errors

    try {
      // const response = await fetch("http://localhost:5001/api/admin-login", {
        const response = await fetch("https://api.blog.footietix.co.uk/api/admin-login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include", // Include cookies for authentication
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        //console.log("Login successful!");

        // Double-check authentication
        // const authCheck = await fetch("http://localhost:5001/api/check-auth", {
          const authCheck = await fetch("https://api.blog.footietix.co.uk/api/check-auth", {
          method: "GET",
          credentials: "include", // Send cookies for validation
        });

        if (authCheck.ok) {
          //console.log("Auth validated successfully.");
          navigate("/admin"); // Redirect to admin dashboard
        } else {
          //console.error("Auth check failed.");
          setError("Login failed. Please try again.");
        }
      } else {
        const data = await response.json();
        setError(data.error || "Failed to login.");
      }
    } catch (err) {
      console.error("Login error:", err); // Debug logs
      setError("Failed to login. Please try again.");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 shadow-lg rounded-lg w-80">
        <h2 className="text-2xl font-bold mb-4 text-center">Admin Login</h2>

        {/* Display Error */}
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

        {/* Password Input */}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter Admin Password"
          className="w-full p-2 mb-4 border rounded"
        />

        {/* Login Button */}
        <button
          onClick={handleLogin}
          disabled={loading} // Disable button when loading
          className={`w-full py-2 rounded text-white ${
            loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          {loading ? "Logging in..." : "Login"} {/* Show spinner or text */}
        </button>
      </div>
    </div>
  );
};

export default AdminLogin;