import React from "react";
import Nav from "./components/Nav"; // Assuming Nav is in components folder
import { Outlet } from "react-router-dom"; // For nested routes

const Layout = () => {
  return (
    <div>
      <Nav /> {/* Always show the Nav */}
      <div className="container mx-auto p-4">
        <Outlet /> {/* Render child routes here */}
      </div>
    </div>
  );
};

export default Layout;