import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa"; // Social Icons

const Nav = () => {
  return (
    <nav className="navbar bg-base-100 shadow-md">
      <div className="w-full px-10 mx-auto flex justify-between items-center">
        {/* Logo */}
        <div>
          <Link to="/" className="font-bold text-xl">
            FootieTix Blog
          </Link>
        </div>

        {/* Nav Links */}
        <div className="flex space-x-6 items-center"> {/* Added items-center */}
          <a
            href="https://footietix.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            About FootieTix
          </a>
          <Link to="/" className="hover:underline">
            Blog
          </Link>

          {/* Social Icons */}
          <a
            href="https://twitter.com/footietix"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            className="hover:text-gray-600"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://instagram.com/footietix"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="hover:text-gray-600"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://tiktok.com/@footietix"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TikTok"
            className="hover:text-gray-600"
          >
            <FaTiktok size={20} />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Nav;