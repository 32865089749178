import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import matter from 'gray-matter';

const BlogEditor = () => {
  const { id } = useParams(); // Get post ID
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState('');

  // Fetch Post Content
  useEffect(() => {
    if (id) {
    //   fetch(`http://localhost:5001/api/get-post/${id}`)
      fetch(`https://api.blog.footietix.co.uk/api/get-post/${id}`)
        .then((res) => res.json())
        .then((data) => {
          //console.log('Fetched Raw Data:', data.content);

          try {
            const parsed = matter(data.content.trim()); // Parse Markdown
            //console.log('Parsed Metadata:', parsed.data);
            //console.log('Parsed Content:', parsed.content);

            // Populate state
            setTitle(parsed.data.title || '');
            setDate(parsed.data.date || '');
            setContent(parsed.content || '');
          } catch (err) {
            console.error('Markdown parsing failed:', err);
            setError('Failed to parse markdown.');
          }
        })
        .catch((err) => {
          console.error('Fetch error:', err);
          setError('Failed to load post.');
        });
    }
  }, [id]);

  // Save Post
  const savePost = async () => {
    if (!title || !content || !date) {
      alert('Title, date, and content are required.');
      return; // Validation
    }

    // Generate ID if not provided
    const postId = id || title.toLowerCase().replace(/ /g, '-');

    // Prepare clean content without metadata
    const cleanedContent = content.replace(/^---[\s\S]*?---\n\n/, '').trim();

    // console.log('Saving Post:', {
    //   id: postId,
    //   title,
    //   date,
    //   content: cleanedContent, // Send plain content only
    // });

    // const response = await fetch('http://localhost:5001/api/save-post', {
        const response = await fetch('https://api.blog.footietix.co.uk/api/save-post', {

      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: postId, // Include metadata separately
        title,
        date,
        content: cleanedContent, // Only the content, no metadata
      }),
    });

    if (response.ok) {
      alert('Post saved successfully!');
    } else {
      const errorData = await response.json();
      alert(`Failed to save the post: ${errorData.error}`);
    }
  };

  return (
    <div className="p-4">
      {error && <p className="text-red-500">{error}</p>}
      {/* Title Input */}
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Post Title"
        className="border p-2 mb-4 w-full"
      />
      {/* Date Input */}
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="border p-2 mb-4 w-full"
      />
      {/* Markdown Editor */}
      <MDEditor
        value={content}
        onChange={setContent}
        height={400}
      />
      {/* Save Button */}
      <button
        onClick={savePost}
        className="bg-green-500 text-white px-4 py-2 mt-4 rounded"
      >
        Save
      </button>
    </div>
  );
};

export default BlogEditor;