import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import matter from "gray-matter";
import remarkGfm from "remark-gfm"; // For tables, checklists, etc.

// Tailwind CSS styles for Markdown elements
const renderers = {
  h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
  h2: ({ children }) => <h2 className="text-2xl font-semibold mb-3">{children}</h2>,
  h3: ({ children }) => <h3 className="text-xl font-medium mb-2">{children}</h3>,
  h4: ({ children }) => <h4 className="text-lg font-medium mb-2">{children}</h4>,
  p: ({ children }) => <p className="text-gray-700 leading-relaxed mb-4">{children}</p>,
  ul: ({ children }) => <ul className="list-disc list-inside mb-4">{children}</ul>,
  ol: ({ children }) => <ol className="list-decimal list-inside mb-4">{children}</ol>,
  li: ({ children }) => <li className="mb-1">{children}</li>,
  blockquote: ({ children }) => (
    <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4 text-gray-600">
      {children}
    </blockquote>
  ),
  code: ({ children }) => (
    <code className="bg-gray-200 text-sm rounded px-1 py-0.5 font-mono">{children}</code>
  ),
  pre: ({ children }) => (
    <pre className="bg-gray-900 text-gray-100 p-4 rounded overflow-x-auto mb-4">
      {children}
    </pre>
  ),
  table: ({ children }) => (
    <table className="table-auto w-full border-collapse border border-gray-300">{children}</table>
  ),
  thead: ({ children }) => <thead className="bg-gray-200">{children}</thead>,
  tr: ({ children }) => <tr className="border-t border-gray-300">{children}</tr>,
  th: ({ children }) => <th className="p-2 border border-gray-300 font-semibold">{children}</th>,
  td: ({ children }) => <td className="p-2 border border-gray-300">{children}</td>,
};

const Post = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [meta, setMeta] = useState({ title: "", date: "" });

  useEffect(() => {
    const loadPost = async () => {
      try {
        const file = await import(`../posts/${id}.md`);
        const text = await fetch(file.default).then((res) => res.text());
        const { content, data } = matter(text);
        setContent(content); // Post content
        setMeta(data); // Frontmatter metadata
      } catch (err) {
        console.error("Failed to load post:", err);
      }
    };
    loadPost();
  }, [id]);

  return (
    <div className="container mx-auto p-4 pt-10">
      <div className="mx-auto px-0 py-6 lg:px-4 max-w-5xl">
        {/* Title and Date */}
        <div className="border-solid border-b-2 mb-4">
            <h1 className="text-3xl font-bold mb-2">{meta.title}</h1>
            <p className="text-gray-500 text-sm mb-4">{meta.date}</p>
        </div>
        
        <div className="mt-4">
            {/* Render Markdown Content */}
        <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
          {content}
        </ReactMarkdown>
        </div>
        
      </div>
    </div>
  );
};

export default Post;